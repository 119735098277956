<template>
  <div class="pt-4 px-3 p-3 ">
      <div id="config" class="flex flex-col border  p-4 rounded-lg bg-white">
          <div v-if="name!='userHistories'" class="flex flex-row">
              <div class="flex w-full">
                <input :placeholder="$t(config.filters.elements.search.options.hint)" v-model="searchText" @keyup.enter="trigger" type="search" autofill="off"  class="border-t  text-gray-700  text-xs border-b border-l rounded-l-md w-full focus:outline-none py-1 px-4">
                <button @click="search" ref="sendReply" class="bg-white rounded-r-md text-green-500  border-t border-r border-b hover:opacity-75  px-3 focus:outline-none">
                    <i class=" py-1 material-icons">search</i> 
                </button>
              </div>
              <div class="">
                <button @click="showMore = !showMore" class="text-white hover:bg-gray-400  
                rounded-full  bg-green-600  flex items-center focus:outline-none p-2 ml-2">
                    <i v-if="showMore" class="material-icons">keyboard_arrow_up</i>
                    <i v-else class="material-icons">keyboard_arrow_down</i>
                </button>
              </div>
          </div>
          <div class="flex flex-col" v-if="name=='userHistories' || name=='sourcings' ? !showMore:showMore">
              <div class="flex flex-row space-x-3 justify-center flex-wrap">
                <div v-for="(item, index) in config.filters.elements.champs" :key="index" v-show="(isShown(item) && item.key!='date_remind') || ((isShown(item) && item.key=='date_remind' && filter['status'] && filter['status_pending'] &&  filter['status_pending'].name && filter['status_pending'].name == 'pending_remind')) || (isShown(item) && filter['status_received'])"> 
                  <div v-if="getShow(item.key) && item.key != 'from' && item.key != 'to'"  class="w-56 mb-4 mt-6" >
                    <div class="border  h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                        <div  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p><label for="seller" class="bg-white text-gray-700 px-1">{{$t(item.label)}} <span :class="$colors.required">*</span></label></p>
                        </div>
                        <div v-if="item.type == 'select' && getShow(item.key)">
                          <div v-if="item.name"> 
                              <span v-if="item.key=='product'">
                                <v-select  :label="item.name" @search="getProducts($event)" v-model="filter[item.key]" :options="products" ></v-select>
                              </span>
                               <span v-else> 
                                  <v-select :class="[config.name==='orders' && item.key==='status_pending' && (!checkstatusorder || checkstatusorder!='Pending')?'disabled':'']"  @input="CheckSellerProducts"   @search="getUsers($event,item.key)"  :label="item.name" v-model="filter[item.key]" :options="item.values" ></v-select>
                               </span>
                          </div>
                          <div v-else> 
                              <v-select @input="setdataSelect"  v-model="filter[item.key]" :options="item.values" ></v-select>                           
                          </div>
                        </div>    
                        <div v-else-if="item.type == 'number'" >
                            <input :min="item.min" :step="item.step" v-model="filter[item.key]" :type="item.type"  :name="item.key" :id="item.key" :class="item.class">
                        </div>
                        <div v-else-if="item.type == 'range_numbers'" >
                         
                          <MultiRangeSlider
                            :min="0"
                            :max="12"
                            baseClassName="multi-range-slider-bar-only"
                            :minValue="item.value.min"
                            :maxValue="item.value.max"
                            @input="UpdateValues"
                          />
                        </div>
                        <div v-else>
                            <!-- <input  v-model="filter[item.key]" :type="item.type" :placeholder="item.placeholder"  :name="item.key" :id="item.key" :class="item.class"> -->

                            <input v-if="item.type != 'date_range'"  v-model="filter[item.key]" :placeholder="item.placeholder" :type="item.type"  :name="item.key" :id="item.key" :class="item.class">
                            <date-picker v-else :placeholder="item.placeholder" @change="DateRange" format="YYYY-MM-DD"  class="w-full-imp" v-model="filter[item.key]" range></date-picker>
                        </div> 
                      
                    </div>
                  </div>
              </div>  
              </div>
               <div v-if="config.filters.hasFilterdate" class="flex flex-row space-x-3 justify-center flex-wrap">
                  <div v-for="(item, index) in config.filters.elements.champs" :key="index" v-show="isShown(item)"> 
                  <div v-if="getShow(item.key) && (item.key == 'from' || item.key == 'to')"  class="w-56 py-2" >
                    <div class="border  h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p><label for="seller" class="bg-white text-gray-700 px-1">{{item.label}} <span :class="$colors.required">*</span></label></p>
                        </div>
                            <input  v-model="filter[item.key]" :type="item.type"  :name="item.key" :id="item.key" :class="item.class">
                    </div>
                  </div>
               </div> 
              </div>
              <div class="flex flex-row justify-center space-x-2 mt-1">
                
                <button  @click="ClearFilters" class="text-green-500  rounded-md mx-1 border border-gray-100 
                 bg-gray-300 hover:bg-green-600   w-1/6 hover:text-white py-2 px-4 focus:outline-none loidingconfirm relative" :class="loading?'noselect':''">
                     {{$t('reset')}}
                </button>
                <button v-if="config.filters.elements.champs.length>0" @click="filtrer_saerch" class="rounded-md mx-1 border border-gray-100 
                 bg-green-600  hover:bg-green-600  text-white py-2 px-4 w-1/6 focus:outline-none loidingconfirm relative" :class="loading?'noselect':''">
                     {{$t('filtrer')}}
                </button>
                
              </div>

          </div>

      </div>
  </div>
</template>

<script>
export default {
  props: {
    config: { type: Object},
    currentUser: { type: Object },
    idWharhouse: { type: Object },
    name:{type:String},
    loading:{required:false},
  },
  data() {
    return {
      showMore: false,
      searchText: "",
      warhouseCountry:"",
      filter: {},
      //idWharhouse:null,
      products:[],
      checkstatusorder:null,
      sellerSelected:null,
      barMinValue: 1,
      barMaxValue: 10,
    };
  },
  computed: {
      warhouseSelected(){
          return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
          },
      },
  watch: {
      'warhouseSelected': async function(oldVal, newVal) {
          //  await this.getwharhouseId();
            
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.getCurrentSellerProducts();
      },
      
  },

async  mounted() {
  //console.log('filter pr----- 1')
  this.warhouseCountry=await this.warhouseSelected;
   //await this.getwharhouseId();
    this.config.filters.elements.champs.forEach((filter) => {
      if (filter.value)
        this.$set(this.filter, filter.key, filter.value, filter.values);
    });    
    //console.log('filter pr----- 2')
    await this.getCurrentSellerProducts();
  },
  methods: {
    UpdateValues(e) {
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;
      if(!this.filter.range_numbers) this.filter.range_numbers={};
     // console.log('filter 11----',this.filter)
      this.filter.range_numbers.min=e.minValue;
      this.filter.range_numbers.max=e.maxValue;
    //  console.log('UpdateValues filter----',this.filter)
    },
    DateRange(data){
    //console.log('data date-------------------', data.map( el => this.$moment(el).format('yyyy-MM-DD')))
   // console.log('data date-------------------', data)
    //console.log('data date 00-------------------',this.$moment(data[0]).format('yyyy-MM-DD'))
   // console.log('Range_updatedAt------',this.filter['Range_updatedAt'])
   // console.log('Range_date------',this.filter['Range_date'])
   
  },
     getShow(champ){
       if((champ==="seller" || champ==="courier" || champ==="zone") && this.currentUser.type ==="Seller") return false
       if((champ==="seller" && this.currentUser.type ==="Seller")) return  false
       if((champ==="status" && (this.currentUser.type ==="CancelledOrder" || this.currentUser.type=='ToRemind'))) return  false 
       if((champ==="status_pending" && (this.currentUser.type ==="CancelledOrder" || this.currentUser.type=='ToRemind'))) return  false 
       else return  true
     },
    trigger() {
      this.$refs.sendReply.click();
    },
    async getwharhouseId(){
          await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
                  this.idWharhouse=res;
              })
    },
   async getCurrentSellerProducts(){
      this.products = [];
     // console.log('filter pr----- 3',this.currentUser)
      //console.log('filter pr----- 4',this.idWharhouse)
      if (this.currentUser && this.currentUser.type == "Seller") {
              const filters = {
                seller: this.currentUser._id,
                'details.warehouse':this.idWharhouse._id,
                limit:10
              };
           //   console.log('filter pr-----',filters)
          const res = await this.$server.search("products",filters);
        //  console.log('filter pr-----',res.content)
          if (res.content.results) {
            this.products = res.content.results;
          } else this.products = [];
        }
    },
      async CheckSellerProducts(seller){
        //console.log("status------",seller)
      if(this.name=="orders" && seller && seller.name && seller.color){
         this.checkstatusorder=seller.name;
      }
      if((this.name=="shippings" || this.name=="orders") && seller && seller.email){
          this.products = [];
          this.sellerSelected=seller._id;
          const filters = {
            seller: seller._id,
            'details.warehouse':this.idWharhouse._id,
             limit:10
          };
          
          const res = await this.$server.search("products", filters);
          
          if (res.content.results) {
            this.products = res.content.results;
          } else {this.products = [];this.sellerSelected=null}
      }
    },
    getUsers: _.debounce(async function(name,type) {
    //  console.log('event______________',name)
     //console.log('warhouset______________',type)
        if(type=="seller" || type=="courier" || type=="zone"){
          await this.$store.dispatch('wharhouse/searchUser',{type: type,warhouse:this.idWharhouse.country,search:name}).then((res) => {
            console.log('res-----------',res)
            const objIndex = this.config.filters.elements.champs.findIndex((obj => obj.key == type));
            this.config.filters.elements.champs[objIndex].values = res
          })
        }
   }, 500), 
   getProducts: _.debounce(async function(name) {
     console.log('event______________',name)
     const filterParams=this.$f.QueryStringToobject();
      console.log('filterParams______________',filterParams)
     const filter={seller: this.sellerSelected,warhouse:this.idWharhouse._id,search:name,notPopulate:true};
     if(filterParams.seller) filter.seller=filterParams.seller;
        await this.$store.dispatch('wharhouse/searchProducts',filter).then((res) => {
            this.products = res;
        })
   }, 500), 
     reset: function (val) {
          this.$emit('reset',val);
      },
      filtrer: function (val) {
          this.$emit('filtrer',val);
      },
      search: function () {
        if(!this.loading) this.$emit('search',this.searchText);
      },
      searchUsers:async function (value,event) {
        console.log('type user',event)
         if(event=="seller" || event=="user") {
           this.$emit('searchSellers',value,event);
         }
         if(event=="courier") this.$emit('searchCouriers',value,event);
      },
      isShown(item) {
      // if(this.config.name === 'expeditions'){
       //  console.log('item exp---',item)
         //console.log('item exp filter---',this.filter['status_received'])
      // }
      let shown = !item.show || (item.show && this.filter[item.show.key] == item.show.equal);
      if(this.config.name === 'orders' && item.key === 'status_pending' && (!this.checkstatusorder || this.checkstatusorder !== 'Pending')) return false
      if(this.config.name === 'expeditions' && item.key === 'status_received' &&  (!this.checkstatusorder || this.checkstatusorder !== 'received')) return false
      if (!shown) this.$set(this.filter, item.key, null);
      return shown;
    },
    setdataSelect(value){
      this.checkstatusorder=value;
    },
    searc() {
      //console.log(this.articleType);
    },

    /*********************************************************************************** */
     async filtrer_saerch() {
      this.filtrer(this.filter);
    },
    async ClearFilters() {
      this.reset(this.filter);
      this.filter = {};
    },
  },
};
</script>

<style>
</style>